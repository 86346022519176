import { ThemeProvider } from "styled-components";
import { BrowserRouter } from "react-router-dom";

import { theme, themeHub, themeMain, uploadPageTheme } from "theme";
import CssReset from "global-styles/css-reset";
import Fonts from "global-styles/fonts";
import PageComponentOnRoute from "routes";

//
const App = () => {
  const isHub = window.location.pathname.includes("/hub");
  let selectedTheme = isHub ? themeHub : theme;
  if (window.location.pathname === "/upload") {
    selectedTheme = uploadPageTheme;
  }
  if (window.location.pathname === "/" || window.location.pathname === "/new") {
    selectedTheme = themeMain;
  }

  return (
    <ThemeProvider theme={selectedTheme}>
      <CssReset bgColor={selectedTheme.color.furthest} />
      <Fonts textColor={selectedTheme.color.closest} />
      <BrowserRouter>
        <PageComponentOnRoute />
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
